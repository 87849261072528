import React, {useContext} from "react";
import "./Skills.scss";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import DbSkill from "../../components/softwareSkills/dbSkills";
import CloudSkills from "../../components/softwareSkills/CloudSkills";
import Projectmanagmentskills from  "../../components/softwareSkills/ProjectManagment";

import {
  illustration, 
  fullstackskillsSection,
  dbskillsSection,
  cloudskillsSection,
  projectmanagmentskillsSection,
} from "../../portfolio";
import {Fade} from "react-reveal";
import database from "../../assets/lottie/database";
import cloud from "../../assets/lottie/cloud";
import qualityAssurance from "../../assets/lottie/quality-assurance";
import projectManagment from "../../assets/lottie/project-managment";
import fullStack from "../../assets/lottie/soft_dev";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";

export default function Skills() {
  const {isDark} = useContext(StyleContext);
  return (
    <div>
      <div style={{textAlign: "center"}}>
        <h1 style={{textAlign: "center"}}>What I do</h1>
        <p style={{textAlign: "center"}}>CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK</p>
      </div>
      <div className={isDark ? "dark-mode main" : "main"} id="skills">
        <div className="skills-main-div">
          <Fade left duration={1000}>
            <div className="skills-image-div" style={{height: "500px", marginTop: "55px"}}>
              {illustration.animated ? (
                <DisplayLottie animationData={fullStack} />
              ) : (
                <img
                  alt="Man Working"
                  src={require("../../assets/images/developerActivity.svg")}
                ></img>
              )}
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="skills-text-div">
              <h1
                className={isDark ? "dark-mode skills-heading" : "skills-heading"}
              >
                {fullstackskillsSection.title}{" "}
              </h1>
              <SoftwareSkill />
              <div>
                {fullstackskillsSection.skills.map((skills, i) => {
                  return (
                    <p
                      key={i}
                      className={
                        isDark
                          ? "dark-mode subTitle skills-text"
                          : "subTitle skills-text"
                      }
                    >
                      {skills}
                    </p>
                  );
                })}
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className={isDark ? "dark-mode main" : "main"} id="skills">
        <div className="skills-main-div">
          <Fade left duration={1000}>
            <div className="skills-image-div" style={{height: "350px",marginTop: "55px"}}>
              {illustration.animated ? (
                <DisplayLottie animationData={database} />
              ) : (
                <img
                  alt="Man Working"
                  src={require("../../assets/images/developerActivity.svg")}
                ></img>
              )}
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="skills-text-div">
              <h1
                className={isDark ? "dark-mode skills-heading" : "skills-heading"}
              >
                {dbskillsSection.title}{" "}
              </h1>
              <DbSkill />
              <div>
                {dbskillsSection.skills.map((skills, i) => {
                  return (
                    <p
                      key={i}
                      className={
                        isDark
                          ? "dark-mode subTitle skills-text"
                          : "subTitle skills-text"
                      }
                    >
                      {skills}
                    </p>
                  );
                })}
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className={isDark ? "dark-mode main" : "main"} id="skills">
        <div className="skills-main-div">
          <Fade left duration={1000}>
            <div className="skills-image-div" style={{height: "400px"}}>
              {illustration.animated ? (
                <DisplayLottie animationData={cloud} />
              ) : (
                <img
                  alt="Man Working"
                  src={require("../../assets/images/developerActivity.svg")}
                ></img>
              )}
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="skills-text-div">
              <h1
                className={isDark ? "dark-mode skills-heading" : "skills-heading"}
              >
                {cloudskillsSection.title}{" "}
              </h1>
              <CloudSkills />
              <div>
                {cloudskillsSection.skills.map((skills, i) => {
                  return (
                    <p
                      key={i}
                      className={
                        isDark
                          ? "dark-mode subTitle skills-text"
                          : "subTitle skills-text"
                      }
                    >
                      {skills}
                    </p>
                  );
                })}
              </div>
            </div>
          </Fade>
        </div>
      </div>

      <div className={isDark ? "dark-mode main" : "main"} id="skills">
        <div className="skills-main-div">
          <Fade left duration={1000}>
            <div className="skills-image-div" style={{height: "400px"}}>
              {illustration.animated ? (
                <DisplayLottie animationData={projectManagment} />
              ) : (
                <img
                  alt="Man Working"
                  src={require("../../assets/images/developerActivity.svg")}
                ></img>
              )}
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="skills-text-div">
              <h1
                className={isDark ? "dark-mode skills-heading" : "skills-heading"}
              >
                {projectmanagmentskillsSection.title}{" "}
              </h1>
              <Projectmanagmentskills />
              <div>
                {projectmanagmentskillsSection.skills.map((skills, i) => {
                  return (
                    <p
                      key={i}
                      className={
                        isDark
                          ? "dark-mode subTitle skills-text"
                          : "subTitle skills-text"
                      }
                    >
                      {skills}
                    </p>
                  );
                })}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
