import React from "react";
import "./SoftwareSkill.scss";
import {projectmanagmentskillsSection} from "../../portfolio";

export default function Projectmanagmentskills() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {projectmanagmentskillsSection.softwareSkills.map((skills, i) => {
            let url = require("../../assets/images/".concat(skills.src));
            return (
              <li
                key={i}
                className="software-skill-inline"
                name={skills.skillName}
              >
                <img
                  alt={skills.alt}
                  src={url}
                ></img>
                <p>{skills.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
